import React from 'react';
import img1 from '../../images/galary/IMG_0479.jpg';
import img2 from '../../images/galary/IMG_0480.jpg';
import img3 from '../../images/galary/IMG_0481.jpg';
import img4 from '../../images/galary/IMG_0482.jpg';
import img5 from '../../images/galary/IMG_0483.jpg';
import img6 from '../../images/galary/IMG_0484.jpg';
import img7 from '../../images/galary/IMG_0485.jpg';
import img8 from '../../images/galary/IMG_0486.jpg';
import img9 from '../../images/galary/IMG_0488.jpg';
import './Gallery.css';

export default function Gallery() {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  return (
    <div className="container my-5">
      <h1 className="text-center display-4 mb-5">Gallery</h1>
      <div className="row">
        {images.map((image, index) => (
          <div key={index} className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="card gallery-card">
              <img src={image} className="card-img-top" alt={`Gallery Images ${index + 1}`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
