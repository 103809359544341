import { Link } from 'react-router-dom';
import './custom-styles.css'; 
import { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg sticky-top rounded shadow-sm p-4 mb-5 custom-navbar">
      <Link className="navbar-brand fw-bolder fs-3" to="/" onClick={closeMenu}>
        Lucky AFH LLC
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMenu}
        aria-controls="navbarNav"
        aria-expanded={isMenuOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
        <ul className="navbar-nav ms-auto fw-bolder fs-3">
          <li className="nav-item">
            <Link className="nav-link" to="/" onClick={closeMenu}>Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about" onClick={closeMenu}>About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/services" onClick={closeMenu}>Services</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gallery" onClick={closeMenu}>Gallery</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact" onClick={closeMenu}>Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
