// useEffect and Intersection Observer to trigger animations on scroll
import React, { useEffect } from "react";
import "./styles.css";
import service from "../../images/realistic-scene-with-health-worker-taking-care-elderly-patient.jpg";
import service2 from "../../images/3972199.jpg";
import service3 from "../../images/4075491.jpg";
import nursing from "../../images/nursing.jpg";
import aging from "../../images/aging_process.jpg";
import assistance from "../../images/assistance.jpg";
import about_us from "../../images/about_us.jpg";
import mission from "../../images/mission.jpg";
import goals from "../../images/goals.jpg";
import family from "../../images/family.jpg";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(
            entry.target.getAttribute("data-animation")!
          );
        }
      });
    });
    const bootstrap = require("bootstrap/dist/js/bootstrap.bundle.min");

    const elements = document.querySelectorAll(".animate-on-scroll");
    elements.forEach((element) => observer.observe(element));
  }, []);

  return (
    <div className="container-fluid bg-light">
      <div className="col text-center">
        <h1 className="display-5 fw-bold slide-in">Welcome to Lucky AFH LLC</h1>
      </div>

      <main>
        <div
          id="myCarousel"
          className="carousel slide mb-6"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              className="active"
              aria-current="true"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item">
              <img
                className="bd-placeholder-img"
                src={service}
                alt="img1"
                width="100%"
                height="100%"
              />
              <div className="container">
                <div className="carousel-caption text-start">
                  <h1 className="text-white">Our Services</h1>
                  <h3>
                    We provide the best home care services for your loved ones.
                  </h3>
                  <br />
                  <p>
                    <Link className="btn btn-lg btn-primary" to={"/services"}>
                      Explore Today
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item active">
              <img
                className="bd-placeholder-img rounded"
                src={service2}
                alt="img2"
                width="100%"
                height="100%"
              />
              <div className="container">
                <div className="carousel-caption">
                  <h1 className="text-white">We'd Love to Hear From You</h1>
                  <p>
                    "Get in touch with us for any inquiries, support, or
                    collaboration opportunities.
                  </p>
                  <br />
                  <p>
                    <Link className="btn btn-lg btn-primary" to="/contact">
                      Contact Us
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="bd-placeholder-img rounded"
                src={service3}
                alt="img3"
                width="100%"
                height="100%"
              />
              <div className="container">
                <div className="carousel-caption text-end">
                  <h1 className="text-white">Explore Our Facility</h1>
                  <p>
                    Take a virtual tour of our state-of-the-art facility.
                  </p>{" "}
                  <br />
                  <p>
                    <Link className="btn btn-lg btn-primary" to={"/gallery"}>
                      Browse Gallery
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <br />
        <div className="container marketing">
          <hr />
          <br />

          <div className="row featurette">
            <div
              className="col-md-6 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <div className="card shadow-lg p-4">
                <div className="card-body">
                  <h1 className="card-title text-primary display-8 mb-4">
                    The aging process brings many questions:
                  </h1>

                  <ul className="card-text mb-4 fw-bold">
                    <li>How long will I maintain my health?</li>
                    <li>How can I remain safe at home?</li>
                    <li>What resources are available?</li>
                  </ul>
                  <p className="card-text mb-4">
                    At <span className="fw-bold">Lucky AFH LLC</span> , we help
                    you proactively plan for a secure future by answering these
                    critical questions and removing uncertainties. Our
                    comprehensive care promotes comfort, safety, and wellness.
                  </p>
                  <p className="card-text">
                    We maximize available benefits and create personalized plans
                    tailored to your needs. Your choice is fundamental, and we
                    respect that in every service we provide.
                  </p>
                </div>
              </div>
              <br />
            </div>
            <div
              className="col-md-6 animate-on-scroll hidden"
              data-animation="slide-in-right"
            >
              <div className="card shadow-sm">
                <img
                  className="card-img-top img-fluid"
                  src={aging}
                  alt="Service images"
                  width="500"
                  height="500"
                />
              </div>
            </div>
          </div>

          <hr className="featurette-divider" />
          <br />
          <br />
          <div className="row featurette">
            <div
              className="col-md-7 order-md-2 animate-on-scroll hidden"
              data-animation="slide-in-right"
            >
              <div className="card shadow-lg p-4">
                <div className="card-body">
                  <h3 className="card-title text-primary display-8 mb-4 fw-bold">
                    Nursing Delegation for Complex Care Situations:
                  </h3>
                  <div className="card-text mb-4 fw-bold">
                    <p>
                      Diabetic Care: Blood Sugar Checks & Insulin Injections
                    </p>
                    <br />
                    <p>
                      Hospice Kit: Administration of Comfort Kit Medications
                    </p>
                    <br />
                    <p>
                      Wound Care: Non-sterile dressing changes depending on the
                      severity of the wound.
                    </p>
                    <br />
                    <p>
                      Ostomy Care: Changing/emptying the bag Nursing Delegation
                      fees vary based on the services required.
                    </p>
                    <br />
                    <p>
                      Our experienced nurses ensure your loved ones receive the
                      highest standard of care through specialized services
                      tailored to their unique needs.
                    </p>
                    <br />
                    <Link to="/services" className="btn btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 order-md-1 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <div className="card shadow-lg p-4">
                <img
                  className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  src={nursing}
                  alt="Nursing Delegation"
                  style={{ borderRadius: "15px" }}
                />
              </div>
            </div>
          </div>
          <br />

          <hr className="featurette-divider" />
          <br />
          <br />
          <div className="row featurette">
            <div
              className="col-md-7 order-md-1 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <div className="card shadow-lg p-4">
                <div className="card-body">
                  <h3 className="card-title text-primary display-8 mb-4 fw-bold">
                    Assistance With Activities of Daily Living Safety/Fall
                  </h3>
                  <div className="card-text mb-4 fw-bold">
                    <h4 className="fw-bold">
                      Assistance With Activities of Daily Living Safety/Fall
                    </h4>
                    <p>
                      Prevention, Personal Hygiene including Showering/Bathing,
                      Dressing, Eating/Feeding, and Continence Care.
                    </p>{" "}
                    <br />
                    <h6>
                      Assistance With Instrumental Activities of Daily Living
                    </h6>
                    <p>
                      Companionship, Light Housekeeping, Medication Reminders,
                      Transportation to Appointments, Shopping/Errands, Meal
                      Prep, Laundry, Assistance with Phone/Internet
                    </p>
                    <br />
                    <p>
                      Care Plans are customized to include assistance with
                      cueing, reassurance and redirection while avoiding any
                      potential triggers for anxiety.
                    </p>
                    <br />
                    <Link to="/services" className="btn btn-primary">
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 order-md-2 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <div className="card shadow-lg p-4">
                <img
                  className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  src={assistance}
                  alt="Nursing Delegation"
                  style={{ borderRadius: "15px" }}
                />
              </div>
            </div>
          </div>
          <br />
         

          <hr className="featurette-divider" />
          <br />
          <br />
          <div className="row">
            <div
              className="col-lg-4 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <img
                className="bd-placeholder-img rounded-circle"
                src={about_us}
                alt="img1"
                width="140"
                height="140"
              />
              <h2 className="fw-normal fw-bold">About Us</h2>
              <p>
              A dedicated provider of home care
              </p>
              <p>
                <Link className="btn btn-primary" to="/services">
                  View details »
                </Link>
              </p>
            </div>
            <div
              className="col-lg-4 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <img
                className="bd-placeholder-img rounded-circle"
                src={mission}
                alt="img1"
                width="140"
                height="140"
              />
              <h2 className="fw-normal fw-bold">Our mission</h2>
              <p>
              Deliver compassionate, high-quality care
              </p>
              <p>
                <Link className="btn btn-primary" to="/services">
                  View details »
                </Link>
              </p>
            </div>
            <div
              className="col-lg-4 animate-on-scroll hidden"
              data-animation="slide-in-left"
            >
              <img
                className="bd-placeholder-img rounded-circle"
                src={goals}
                alt="img1"
                width="140"
                height="140"
              />
              <h2 className="fw-normal fw-bold">Our Goals</h2>
              <p>
              Building strong relationships based on trust
              </p>
              <p>
                <Link className="btn btn-primary" to="/services">
                  View details »
                </Link>
              </p>
            </div>
          </div><br/>
          <hr className="featurette-divider" />
        </div>
      </main>
    </div>
  );
};

export default Home;
