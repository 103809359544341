import React from 'react';
import { useParams } from 'react-router-dom';
import { services } from './servicesData';

const ServiceDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const service = services.find(service => service.id === +id!);

  if (!service) {
    return <div className="container"><p>Service not found.</p></div>;
  }

  return (
    <div className="container my-5">
      <h1 className="display-5">{service.title}</h1>
      <img src={service.image} alt={service.title} className="img-fluid mb-4" />
      <p>{service.fullDescription}</p>
    </div>
  );
}

export default ServiceDetail;
