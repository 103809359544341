import React from 'react';
import { Link } from 'react-router-dom';
import { services } from './servicesData';
import './styles.css'; // Assuming you have the styles in this file

const Services: React.FC = () => {
  return (
    <div className="container">
      <h1 className="text-center display-4 my-5 service-header">Our Services</h1>
      <div className="row">
        {services.map(service => (
          <div key={service.id} className="col-sm-12 col-md-6 col-lg-4 mb-4">
            <div className="card service-card shadow-sm h-100">
              <img src={service.image} className="card-img-top" alt={service.title} />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
                <Link to={`/services/${service.id}`} className="btn btn-primary mt-auto">Learn More</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
