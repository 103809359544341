import service1 from "../../images/3946241.jpg";
import service2 from "../../images/meal3.jpg";
import service3 from "../../images/4219290.jpg";
import Dementia_Care from "../../images/Dementia_Care.jpg";
import Fall_Prevention from "../../images/Fall_Prevention.jpg";
import Hospice_Support from "../../images/Hospice_Support.jpg";
import Respite_Care from "../../images/Respite_Care.jpg";
import house_keeping from "../../images/house_keeping.jpg";
import transportation from "../../images/transportation.jpg";
import compasionate_care from "../../images/compasionate_care.jpg";



export interface Service {
    id: number;
    title: string;
    description: string;
    fullDescription: string;
    image: string;
}

export const services: Service[] = [
    { 
        id: 1, 
        title: 'Personal Care', 
        description: 'We assist with personal hygiene, bathing, grooming, and dressing to maintain dignity and health.', 
        fullDescription: `Our personal care services are designed to help individuals with their daily activities, ensuring they maintain their dignity and health. We understand that personal hygiene is crucial for both physical and mental well-being, which is why our caregivers provide compassionate assistance with bathing, grooming, and dressing. This not only helps maintain cleanliness but also boosts self-esteem and confidence.

        Additionally, our personal care services are tailored to meet the specific needs of each client. Whether it's assisting with hair care, shaving, or selecting appropriate clothing, our goal is to ensure that clients feel comfortable and well-cared for. We strive to create a positive and respectful environment, where clients feel safe and valued while receiving the care they need.` , 
        image: service1 
    },
    { 
        id: 2, 
        title: 'Meal Preparation', 
        description: 'Our caregivers prepare nutritious meals that accommodate dietary restrictions and preferences.', 
        fullDescription: `Nutrition plays a vital role in maintaining overall health, especially for individuals with specific dietary needs. Our meal preparation services ensure that clients receive balanced and nutritious meals that cater to their personal preferences and dietary restrictions. We collaborate with clients and their families to plan menus that are not only healthy but also enjoyable, promoting better eating habits and overall wellness.

        Our caregivers are trained to prepare meals that are low in sodium, sugar, or fat, depending on the client's health requirements. We also focus on portion control and the inclusion of fresh, wholesome ingredients. Whether it's preparing a comforting breakfast, a hearty lunch, or a light dinner, our caregivers are committed to making mealtime a pleasant and nourishing experience for our clients.` , 
        image: service2
    },
    { 
        id: 3, 
        title: 'Medication Management', 
        description: 'We ensure medications are taken as prescribed to avoid missed doses or overdoses.', 
        fullDescription: `Proper medication management is essential for individuals with chronic conditions or multiple prescriptions. Our caregivers are dedicated to helping clients manage their medications effectively, ensuring that they are taken at the correct times and in the correct doses. This reduces the risk of complications, such as missed doses or accidental overdoses, which can have serious health consequences.

        In addition to organizing and administering medications, our caregivers also monitor for potential side effects or adverse reactions. They keep detailed records of medication intake and communicate regularly with healthcare providers and family members. This comprehensive approach ensures that clients receive the full benefit of their medications, while also maintaining their overall health and safety.` , 
        image: service3 
    },
    { 
        id: 4, 
        title: 'Housekeeping Services', 
        description: 'We handle light housekeeping tasks such as cleaning, laundry, and organizing living spaces.', 
        fullDescription: `A clean and organized home is crucial for maintaining a safe and comfortable living environment. Our housekeeping services cover a wide range of tasks, including dusting, vacuuming, laundry, and organizing personal spaces. These services are designed to reduce the physical burden on clients, allowing them to live in a clean and tidy environment without the stress of maintaining it themselves.

        Our caregivers pay attention to detail, ensuring that every corner of the home is kept clean and orderly. From changing bed linens to sanitizing kitchen and bathroom surfaces, our goal is to create a welcoming and hygienic space where clients can relax and enjoy their daily lives. We believe that a well-maintained home contributes significantly to a client's overall well-being.` , 
        image: house_keeping 
    },
    { 
        id: 5, 
        title: 'Transportation Assistance', 
        description: 'We provide transportation to medical appointments, grocery shopping, and other essential outings.', 
        fullDescription: `Maintaining independence and mobility is important for individuals who need regular access to medical care and other essential services. Our transportation assistance services ensure that clients can safely and reliably get to their medical appointments, grocery stores, or social activities. This service is especially valuable for those who no longer drive or have limited access to public transportation.

        Our caregivers provide door-to-door service, helping clients navigate to and from their destinations with ease. Whether it's a routine check-up, a trip to the pharmacy, or an outing to visit friends, we make sure that clients arrive safely and on time. This service not only supports their health but also helps them stay connected to their community, enhancing their overall quality of life.` , 
        image: transportation 
    },
    { 
        id: 6, 
        title: 'Companionship Care', 
        description: 'Our caregivers offer companionship and social interaction to prevent loneliness and improve mental health.', 
        fullDescription: `Social interaction is a key component of mental and emotional well-being, especially for those who may feel isolated or lonely. Our companionship care services provide clients with meaningful social interaction, whether through conversation, playing games, or sharing hobbies. Our caregivers become trusted companions, offering both emotional support and friendship.

        Beyond just keeping clients company, our caregivers engage them in activities that stimulate their minds and spirits. From going on walks to participating in hobbies like knitting or gardening, we tailor our companionship services to match the interests and abilities of each client. This not only helps prevent loneliness but also enhances the client’s overall mood and outlook on life.` , 
        image: compasionate_care 
    },
    { 
        id: 7, 
        title: 'Respite Care', 
        description: 'Temporary relief for family caregivers, ensuring their loved ones are well cared for during breaks.', 
        fullDescription: `Caring for a loved one can be a rewarding experience, but it can also be physically and emotionally demanding. Our respite care services provide family caregivers with the opportunity to take a much-needed break, knowing that their loved ones are in good hands. We offer short-term care that allows caregivers to rest, travel, or simply take time for themselves.

        During respite care, our trained caregivers provide the same high level of care and attention that clients are accustomed to. Whether it’s for a few hours, a day, or even a week, we ensure that the client’s routine and care plan are followed closely. This service not only supports the well-being of the primary caregiver but also provides clients with a change of pace and interaction with new caregivers.` , 
        image: Respite_Care 
    },
    { 
        id: 8, 
        title: 'Dementia Care', 
        description: 'Specialized care for individuals with Alzheimer’s or dementia, including memory support and safety monitoring.', 
        fullDescription: `Caring for individuals with Alzheimer’s or other forms of dementia requires specialized knowledge and a compassionate approach. Our dementia care services are designed to provide a safe and supportive environment for those living with cognitive impairments. We focus on maintaining the client’s independence and dignity while providing the necessary support to manage daily activities and behavioral changes.

        Our caregivers are trained in techniques to manage symptoms such as memory loss, confusion, and agitation. We also work closely with families to develop personalized care plans that address the unique needs of each client. By providing consistent care and support, we help clients maintain their quality of life and reduce the stress and uncertainty associated with dementia.` , 
        image: Dementia_Care 
    },
    { 
        id: 9, 
        title: 'Fall Prevention', 
        description: 'We assess home safety and implement strategies to reduce the risk of falls.', 
        fullDescription: `Falls are a leading cause of injury among older adults, and preventing them is a top priority in our care plans. Our fall prevention services include a comprehensive assessment of the client’s home to identify potential hazards. We then implement strategies such as rearranging furniture, installing grab bars, and providing mobility aids to reduce the risk of falls.

        In addition to making physical modifications to the home, our caregivers work with clients to improve their balance and strength through exercises and activities. We also educate clients and their families on safe practices to prevent falls in daily activities. By creating a safer living environment, we help clients maintain their independence and reduce the likelihood of injury.` , 
        image: Fall_Prevention 
    },
    { 
        id: 10, 
        title: 'Hospice Support', 
        description: 'Comfort-focused care for individuals in the final stages of life, supporting both the client and their family.', 
        fullDescription: `Hospice support is about providing compassionate care and comfort during the final stages of life. Our caregivers are dedicated to ensuring that clients experience as much comfort and dignity as possible. We focus on pain management, emotional support, and assisting with daily activities that become increasingly challenging in the later stages of life.

        We also provide significant support to the client’s family, offering guidance and comfort during what is often a difficult time. Our hospice services are designed to respect the wishes of the client and their family, providing a peaceful and supportive environment. We work closely with healthcare providers to ensure that all aspects of care are coordinated, allowing the client to spend their final days with dignity and grace.` , 
        image: Hospice_Support 
    },
];
