import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer bg-dark text-white">
      <div className="container py-5">
        <div className="row">
          {/* Contact Information */}
          <div className="col-lg-4 col-md-12 mb-4">
            <h5>Contact Us</h5>
            <p>(206) 218-4578</p>
            <p>luckyafhllc@gmail.com</p>
            <div>
              <Link to="/contact" className="btn btn-primary fw-bold">
                Contact Us
              </Link>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="col-lg-4 col-md-6 mb-4">
            <h5>Quick Links</h5>
            <ul className="list">
              <li><Link to="/" className="text-white">Home</Link></li>
              <li><Link to="/about" className="text-white">About Us</Link></li>
              <li><Link to="/services" className="text-white">Services</Link></li>
              <li><Link to="/contact" className="text-white">Contact Us</Link></li>
            </ul>
          </div>

          {/* Location Map */}
          <div className="col-lg-4 col-md-6 mb-4">
            <h5>Our Location</h5>
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.5074326453083!2d-122.3479190242437!3d47.7688296790909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549014ffe7cb2b25%3A0xabce9a712dd0a0ef!2s19534%20Fremont%20Ave%20N%2C%20Shoreline%2C%20WA%2098133%2C%20USA!5e0!3m2!1sen!2s!4v1693086200000!5m2!1sen!2s"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Location"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="text-center py-4">
          <p>&copy; {new Date().getFullYear()} Lucky AFH LLC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
