import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
import serviceImage from "../../images/3946241.jpg";

const About: React.FC = () => {
  return (
    <div className="container my-5">
      <h1 className="display-4 text-center mb-5 about-header">About Us</h1>

      <div className="row mb-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="h3 about-title">Who We Are</h2>
              <p className="about-text">
                Lucky Adult Family Home is a dedicated provider of home care
                services, committed to enhancing the lives of the elderly. Our
                team of compassionate professionals offers personalized care
                that emphasizes respect, integrity, and community.
              </p>
            </div>
            <div className="col-md-6 text-center">
              <img
                src={serviceImage}
                alt="Who We Are"
                className="img-fluid mb-3 rounded shadow-lg"
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-6 mb-4">
          <div className="card shadow-lg h-100">
            <div className="card-body">
              <h2 className="h3 about-title">Our Services</h2>
              <p className="about-text">
                We offer tailored services to meet individual needs, including
                personal care, companionship, meal preparation, medication
                management, housekeeping, and transportation.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card shadow-lg h-100">
            <div className="card-body">
              <h2 className="h3 about-title">Our Goals</h2>
              <p className="about-text">
                Our aim is to provide exceptional care, enabling our clients to
                maintain independence and dignity. We focus on enhancing
                well-being through personalized care plans, building strong
                relationships, continuously improving our services, and
                fostering a positive environment for our caregivers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <div className="card shadow-lg h-100">
            <div className="card-body">
              <h2 className="h3 about-title">Our Mission</h2>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img
                    src={serviceImage}
                    alt="Our Mission"
                    className="img-fluid mb-3 rounded shadow-lg"
                    style={{ maxHeight: "300px" }}
                  />
                </div>
                <div className="col-md-6">
                  <p className="about-text">
                    Our mission is to be the community's preferred home care
                    provider, offering superior care that enriches the lives of
                    our clients. We are dedicated to delivering compassionate,
                    high-quality care, empowering clients to live independently
                    and safely, supporting their emotional, physical, and social
                    well-being, and promoting excellence and continuous learning
                    among our staff.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card shadow-lg h-100">
            <div className="card-body">
              <h2 className="h3 about-title">Contact Us</h2>
              <p className="about-text">
                Have questions or want to learn more?{" "}
                <Link className="about-link" to="/contact">
                  Contact us
                </Link>{" "}
                today. We're here to help and look forward to serving you and
                your loved ones.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
